import {Injectable} from "@angular/core";

@Injectable()
export class MessageResponse {
  body: string;
  value: string;
  type: string;
  isclient: boolean;
  date: string;
  isButtom: boolean;
}
