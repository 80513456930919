import { Component, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TokenResponse } from '../classes/responses/token-response';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MessageResponse } from '../classes/responses/message-response';
import { MsgResponse } from '../classes/responses/msg-response';
import { md5 } from '../classes/md5';
import { HistoryResponse } from '../classes/responses/history-response';
import { IonContent } from '@ionic/angular';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss'],
})
export class HomePage {
    public sessao: string;
    public token: string;
    public msg: string;
    private fix_header: HttpHeaders;
    public itens: any = [];

    @ViewChild('content', { static: false }) content: IonContent;

    constructor(public http: HttpClient, public storage: Storage, public router: Router,
        private titleService: Title) {

        this.storage.get('sessao')
            .then(
                (sessao) => {
                    this.sessao = sessao;
                    this.msg = '';

                    // if (this.sessao) {
                    //     this.history();
                    // } else {
                    this.sessao = md5(new Date().toLocaleString());
                    this.storage.set('sessao', this.sessao);
                    this.msg = '';
                    this.login();
                    const mensagemInicial: MessageResponse = new MessageResponse();
                    mensagemInicial.body = 'Olá, você está no atendimento da Nacional Gás. Siga as instruções para validar o código do vale gás social e liberar a entrega do botijão ao beneficiário.';
                    mensagemInicial.isclient = false;
                    mensagemInicial.type = 'text';
                    mensagemInicial.date = new Date().toLocaleString();
                    this.itens.push(mensagemInicial);
                    const mensagemInicial1: MessageResponse = new MessageResponse();
                    mensagemInicial1.body = 'Digite o CNPJ da revenda para iniciar. Insira apenas os números. Não é necessário (.) ou (/).';
                    mensagemInicial1.isclient = false;
                    mensagemInicial1.type = 'text';
                    mensagemInicial1.date = new Date().toLocaleString();
                    this.itens.push(mensagemInicial1);

                    setTimeout(() => {
                        this.getContent().scrollToBottom(500);
                    }, 500);
                    // }
                },
                (erro) => {
                    this.sessao = md5(new Date().toLocaleString());
                    this.storage.set('sessao', this.sessao);
                    this.msg = '';
                    this.login();
                    const mensagemInicial: MessageResponse = new MessageResponse();
                    mensagemInicial.body = 'Olá, você está no atendimento da Nacional Gás. Siga as instruções para validar o código do vale gás social e liberar a entrega do botijão ao beneficiário.';
                    mensagemInicial.isclient = false;
                    mensagemInicial.type = 'text';
                    mensagemInicial.date = new Date().toLocaleString();
                    this.itens.push(mensagemInicial);

                    setTimeout(() => {
                        this.getContent().scrollToBottom(500);
                    }, 500);
                }
            );


    }

    getContent() {
        return this.content;
    }
    // Get Parent Route if any
    parentUrl() {
        return this.router.url.split('/')[1];
    }

    // Get Child Route if any
    childUrl() {
        return this.router.url.split('/')[2];
    }

    // Set Title
    setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    login(): void {
        this.fix_header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Access-Control-Expose-Headers': '*'
        });
        this.http.post<TokenResponse>("https://ngws.fpenatech.com.br/api/auth/login",
            { email: "dscdarlan@gmail.com", password: "Wiser@2020" },
            { headers: this.fix_header, observe: 'response' }).subscribe(loginData => {
                //console.log("Requisição Finalizada");
                //console.log(loginData);
                this.token = loginData.body.Authorization;
            }, (error: HttpErrorResponse) => {
                this.storage.clear();

            });
    }
    history(): void {

        this.fix_header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Access-Control-Expose-Headers': '*'
        });
        this.http.post<TokenResponse>("https://ngws.fpenatech.com.br/api/auth/login",
            { email: "dscdarlan@gmail.com", password: "Wiser@2020" },
            { headers: this.fix_header, observe: 'response' }).subscribe(loginData => {
                //console.log("Requisição Finalizada");
                //console.log(loginData);
                this.token = loginData.body.Authorization;
                this.fix_header = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Authorization': 'Bearer ' + this.token,
                    'Access-Control-Expose-Headers': '*'
                });

                // headers = new HttpHeaders();
                // headers = headers.set('authorization', this.token);
                this.http.post<HistoryResponse[]>("https://ngws.fpenatech.com.br/api/getChatMessages",
                    { contato: this.sessao },
                    { headers: this.fix_header, observe: 'response' }).subscribe(loginData1 => {
                        //console.log("Requisição Finalizada");
                        //console.log(loginData1);
                        if (loginData1.status == 204) {
                            this.sessao = md5(new Date().toLocaleString());
                            this.storage.set('sessao', this.sessao);
                            this.msg = '';
                            this.login();
                            const mensagemInicial: MessageResponse = new MessageResponse();
                            mensagemInicial.body = 'Olá, você está no atendimento da Nacional Gás. Siga as instruções para validar o código do vale gás social e liberar a entrega do botijão ao beneficiário.';
                            mensagemInicial.isclient = false;
                            mensagemInicial.type = 'text';
                            mensagemInicial.date = new Date().toLocaleString();
                            this.itens.push(mensagemInicial);
                            setTimeout(() => {
                                this.getContent().scrollToBottom(500);
                            }, 500);
                        } else {

                            this.itens = [];
                            for (let it of loginData1.body) {
                                const mensagemInicial: MessageResponse = new MessageResponse();
                                mensagemInicial.body = it.msg;
                                mensagemInicial.isclient = (it.fromClient == 1);
                                mensagemInicial.type = 'text';
                                mensagemInicial.date = new Date(it.data_msg).toLocaleString();
                                this.itens.push(mensagemInicial);
                            }
                            setTimeout(() => {
                                this.getContent().scrollToBottom(500);
                            }, 500);
                        }

                    }, (error: HttpErrorResponse) => {
                        this.sessao = md5(new Date().toLocaleString());
                        this.storage.set('sessao', this.sessao);
                        this.msg = '';
                        this.login();
                        const mensagemInicial: MessageResponse = new MessageResponse();
                        mensagemInicial.body = 'Olá, você está no atendimento da Nacional Gás. Siga as instruções para validar o código do vale gás social e liberar a entrega do botijão ao beneficiário.';
                        mensagemInicial.isclient = false;
                        mensagemInicial.type = 'text';
                        mensagemInicial.date = new Date().toLocaleString();
                        this.itens.push(mensagemInicial);
                        setTimeout(() => {
                            this.getContent().scrollToBottom(500);
                        }, 500);
                    });

            }, (error: HttpErrorResponse) => {

            });



    }
    send(): void {
        let msg = this.msg;
        this.msg = '';
        this.fix_header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Authorization': 'Bearer ' + this.token,
            'Access-Control-Expose-Headers': '*'
        });

        if (msg) {
            const mensagemInicial: MessageResponse = new MessageResponse();
            mensagemInicial.body = msg;
            mensagemInicial.isclient = true;
            mensagemInicial.type = 'text';
            mensagemInicial.date = new Date().toLocaleString();
            this.itens.push(mensagemInicial);
            setTimeout(() => {
                this.getContent().scrollToBottom(500);
            }, 500);

            this.http.post<MsgResponse>("https://ngws.fpenatech.com.br/api/msgNgas",
                { mensagem: msg, contato: this.sessao },
                { headers: this.fix_header, observe: 'response' }).subscribe(loginData => {
                    console.log("Requisição Finalizada");
                    console.log(loginData);

                    const mensagemReceiver: MessageResponse = new MessageResponse();
                    mensagemReceiver.body = loginData.body.msgs[0].body;
                    mensagemReceiver.isclient = false;
                    mensagemReceiver.type = loginData.body.msgs[0].type;
                    mensagemReceiver.date = new Date().toLocaleString();
                    mensagemReceiver.isButtom = (loginData.body.msgs.length > 1);
                    this.itens.push(mensagemReceiver);


                    setTimeout(() => {
                        this.getContent().scrollToBottom(500);
                    }, 500);
                    this.msg = '';
                }, (error: HttpErrorResponse) => {
                    this.msg = '';
                });
        }
    }

    sendYes(): void {
        let msg = "Sim";
        this.msg = '';
        this.fix_header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Authorization': 'Bearer ' + this.token,
            'Access-Control-Expose-Headers': '*'
        });

        if (msg) {
            const mensagemInicial: MessageResponse = new MessageResponse();
            mensagemInicial.body = msg;
            mensagemInicial.isclient = true;
            mensagemInicial.type = 'text';
            mensagemInicial.date = new Date().toLocaleString();
            this.itens.push(mensagemInicial);
            setTimeout(() => {
                this.getContent().scrollToBottom(500);
            }, 500);

            this.http.post<MsgResponse>("https://ngws.fpenatech.com.br/api/msgNgas",
                { mensagem: msg, contato: this.sessao },
                { headers: this.fix_header, observe: 'response' }).subscribe(loginData => {
                    console.log("Requisição Finalizada");
                    console.log(loginData);

                    const mensagemReceiver: MessageResponse = new MessageResponse();
                    mensagemReceiver.body = loginData.body.msgs[0].body;
                    mensagemReceiver.isclient = false;
                    mensagemReceiver.type = loginData.body.msgs[0].type;
                    mensagemReceiver.date = new Date().toLocaleString();
                    mensagemReceiver.isButtom = (loginData.body.msgs.length > 1);
                    this.itens.push(mensagemReceiver);


                    setTimeout(() => {
                        this.getContent().scrollToBottom(500);
                    }, 500);
                    this.msg = '';
                }, (error: HttpErrorResponse) => {
                    this.msg = '';
                });
        }
    }
    sendNo(): void {
        let msg = "Não";
        this.msg = '';
        this.fix_header = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://ngws.fpenatech.com.br',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Authorization': 'Bearer ' + this.token,
            'Access-Control-Expose-Headers': '*'
        });

        if (msg) {
            const mensagemInicial: MessageResponse = new MessageResponse();
            mensagemInicial.body = msg;
            mensagemInicial.isclient = true;
            mensagemInicial.type = 'text';
            mensagemInicial.date = new Date().toLocaleString();
            this.itens.push(mensagemInicial);
            setTimeout(() => {
                this.getContent().scrollToBottom(500);
            }, 500);

            this.http.post<MsgResponse>("https://ngws.fpenatech.com.br/api/msgNgas",
                { mensagem: msg, contato: this.sessao },
                { headers: this.fix_header, observe: 'response' }).subscribe(loginData => {
                    console.log("Requisição Finalizada");
                    console.log(loginData);

                    const mensagemReceiver: MessageResponse = new MessageResponse();
                    mensagemReceiver.body = loginData.body.msgs[0].body;
                    mensagemReceiver.isclient = false;
                    mensagemReceiver.type = loginData.body.msgs[0].type;
                    mensagemReceiver.date = new Date().toLocaleString();
                    mensagemReceiver.isButtom = (loginData.body.msgs.length > 1);
                    this.itens.push(mensagemReceiver);


                    setTimeout(() => {
                        this.getContent().scrollToBottom(500);
                    }, 500);
                    this.msg = '';
                }, (error: HttpErrorResponse) => {
                    this.msg = '';
                });
        }
    }

    ngOnInit() {
        this.setTitle('Nacional Gás Chat');
    }
}
